import React, { useState } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";
import Wrapper from "../components/layouts/standard-wrapper";
import { Link } from "gatsby";
import { navigate } from "gatsby";
import { BackButton } from "../components/buttons/buttons";
import { PlanCard } from "../components/cards/plan-cards";
import Recover from "../components/layouts/recover-plan";
import Masterplan from "../components/layouts/masterplan-plan";
import RecoverIcon from "../images/icons/recover.svg";
import MasterplanIcon from "../images/icons/masterplan.svg";
import EarthworksIcon from "../images/icons/earthworks.svg";
import { useDispatch, useSelector } from "react-redux";
import { addSubProject } from "../store/ducks/general";
import UserRoute from "../components/routes/userRoute";
import { userRoles } from "../components/utilities/utilities";

const Container = styled.div`
  p {
    font-size: 1.25rem;
  }
`;
const Title = styled.section`
  padding: 0 0 1rem 0;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.uiPalette.dark.highGray};
  h1 {
    padding: 2rem 0 0 0;
  }
`;

const Step1 = styled.section`
  padding: 3.75rem 0rem 1rem 0rem;
  .product-row {
    padding: 1.5rem 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
  }
  .recover-button {
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    margin-top: 100px;
    align-self: flex-start;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 0.8125rem;
    background: ${(props) =>
      props.theme.variables.buttons.secondary.background};
    border: 1px solid
      ${(props) => props.theme.variables.buttons.secondary.background};
    border-radius: 25rem;
    padding: 0.9375rem 1.875rem;
    color: ${(props) =>
      props.theme.variables.buttons.secondary.color} !important;
    &:hover {
      background: ${(props) =>
        props.theme.variables.buttons.secondary.background}30;
      color: ${(props) =>
        props.theme.variables.buttons.secondary.hoverColor} !important;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
      width: 100%;
    }
  }
  #recover {
    .plan-icon {
      path {
        stroke: ${(props) => props.theme.variables.cards.product.color};
      }
    }
    &:hover {
      border: 1px solid ${(props) => props.theme.colors.primaryColors.blue};
      h4 {
        color: ${(props) => props.theme.colors.primaryColors.blue};
      }
      .plan-icon {
        border: none;
        path {
          stroke: ${(props) => props.theme.colors.primaryColors.blue};
        }
      }
    }
  }
  .recover-active {
    border: 1px solid ${(props) => props.theme.colors.primaryColors.blue};
    h4 {
      color: ${(props) => props.theme.colors.primaryColors.blue};
    }
    .plan-icon {
      path {
        stroke: ${(props) => props.theme.colors.primaryColors.blue} !important;
      }
    }
    .recover-button {
      border: transparent !important;
      background: transparent !important;
      color: ${(props) => props.theme.colors.primaryColors.blue} !important;
      padding: 0;
    }
  }
  .masterplan-button {
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    align-self: flex-start;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 0.8125rem;
    background: ${(props) => props.theme.variables.buttons.primary.background};
    border: 1px solid
      ${(props) => props.theme.variables.buttons.primary.background};
    border-radius: 25rem;
    padding: 0.9375rem 1.875rem;
    color: ${(props) => props.theme.variables.buttons.primary.color} !important;
    &:hover {
      background: ${(props) =>
        props.theme.variables.buttons.primary.background}30;
      color: ${(props) =>
        props.theme.variables.buttons.primary.hoverColor} !important;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
      width: 100%;
    }
  }
  #masterplan {
    .plan-icon {
      ellipse,
      circle {
        stroke: ${(props) => props.theme.variables.cards.product.color};
      }
    }
    &:hover {
      border: 1px solid ${(props) => props.theme.colors.secondaryColors.orange};
      h4 {
        color: ${(props) => props.theme.colors.secondaryColors.orange};
      }
      .plan-icon {
        border: none;
        ellipse,
        circle {
          stroke: ${(props) => props.theme.colors.secondaryColors.orange};
        }
      }
    }
  }
  .masterplan-active {
    border: 1px solid ${(props) => props.theme.colors.secondaryColors.orange};
    h4 {
      color: ${(props) => props.theme.colors.secondaryColors.orange};
    }
    .plan-icon {
      ellipse,
      circle {
        stroke: ${(props) =>
          props.theme.colors.secondaryColors.orange} !important;
      }
    }
    .masterplan-button {
      border: transparent !important;
      background: transparent !important;
      color: ${(props) => props.theme.colors.secondaryColors.orange} !important;
      padding: 0;
    }
  }
  .earthworks-button {
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    align-self: flex-start;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 0.8125rem;
    background: ${(props) => props.theme.variables.buttons.tertiary.background};
    border: 1px solid
      ${(props) => props.theme.variables.buttons.tertiary.background};
    border-radius: 25rem;
    padding: 0.9375rem 1.875rem;
    color: ${(props) =>
      props.theme.variables.buttons.tertiary.color} !important;
    &:hover {
      background: ${(props) =>
        props.theme.variables.buttons.tertiary.background}30;
      color: ${(props) =>
        props.theme.variables.buttons.tertiary.hoverColor} !important;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
      width: 100%;
    }
  }
  #earthworks {
    .plan-icon {
      max-width: 50px;
      path {
        stroke: ${(props) => props.theme.variables.cards.product.color};
      }
    }
    &:hover {
      border: 1px solid ${(props) => props.theme.colors.secondaryColors.green};
      h4 {
        color: ${(props) => props.theme.colors.secondaryColors.green};
      }
      .plan-icon {
        border: none;
        path {
          stroke: ${(props) => props.theme.colors.secondaryColors.green};
        }
      }
    }
  }
  .inactive {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  .active {
    opacity: 1;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    .product-row {
      grid-template-columns: 1fr;
    }
  }
`;

const Step2 = styled.section``;

const Contact = () => {
  const loginValues = useSelector((state) => state.general.loginValues);

  if (loginValues && loginValues.role == userRoles.CLIENT_VIEWER) {
    navigate("../");
  }

  const dispatch = useDispatch();

  // Translation component
  const { t } = useTranslation();

  const goToSubProject = (subProjectData) => {
    dispatch(addSubProject(subProjectData.sub_project));
    window.localStorage.setItem("auth", JSON.stringify({ ...loginValues }));
    window.localStorage.setItem(
      "subProjectData",
      JSON.stringify(subProjectData)
    );
    navigate("../u-collect-dashboard/dashboard");
  };

  // Toggle display of which plan tier user is selecting
  const [allPlans, setAllPlans] = useState({
    recover: {
      status: "active",
      button: t("recover.select-recover.cta"),
      section: "hide-content",
    },
    masterplan: {
      status: "active",
      button: t("masterplan.select-masterplan.cta"),
      section: "hide-content",
    },
    earthworks: "active",
  });
  const selectPlan = (plan) => {
    if (plan === "recover") {
      navigate("#recover-tier");
      setAllPlans({
        recover: {
          status: "recover-active",
          button: "Recover Selected",
          section: "show-content",
        },
        masterplan: {
          status: "inactive",
          button: "Select MasterPlan",
          section: "hide-content",
        },
        earthworks: "inactive",
      });
    } else if (plan === "masterplan") {
      navigate("#masterplan-tier");
      setAllPlans({
        recover: {
          status: "inactive",
          button: "Select Recover",
          section: "hide-content",
        },
        masterplan: {
          status: "masterplan-active",
          button: "MasterPlan Selected",
          section: "show-content",
        },
        earthworks: "inactive",
      });
    } else if (plan === "show-all") {
      navigate("#plan-select");
      setAllPlans({
        recover: {
          status: "active",
          button: "Select Recover",
          section: "hide-content",
        },
        masterplan: {
          status: "active",
          button: "Select MasterPlan",
          section: "hide-content",
        },
        earthworks: "inactive",
      });
    }
  };
  return (
    <UserRoute>
      <Layout goToSubProject={goToSubProject}>
        <Wrapper>
          <Container>
            <Title>
              <BackButton />
              <h1>{t("request-new-project.h1")}</h1>
            </Title>
            <Step1 id="plan-select">
              <h2>{t("step-1")}</h2>
              <p>{t("step-1.description")}</p>
              <div className="product-row">
                <PlanCard
                  styleName={"recover"}
                  status={allPlans.recover.status}
                >
                  <div className="card-content">
                    <RecoverIcon
                      className="plan-icon"
                      alt="Recover product icon"
                    />
                    <h4>{t("recover")}</h4>
                    <h5>{t("recover.description")}</h5>
                    <a
                      href="https://asterra.io/products/recover/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="recover-link"
                    >
                      {t("learn-more.cta")}
                    </a>
                  </div>
                  <button
                    className="recover-button"
                    onClick={() => selectPlan("recover")}
                  >
                    {allPlans.recover.button}
                  </button>
                </PlanCard>
                <PlanCard
                  styleName={"masterplan"}
                  status={allPlans.masterplan.status}
                >
                  <div className="card-content">
                    <MasterplanIcon
                      className="plan-icon"
                      alt="MasterPlan product icon"
                    />
                    <h4>{t("masterplan")}</h4>
                    <h5>{t("masterplan.description")}</h5>
                    <a
                      href="https://asterra.io/products/masterplan/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="recover-link"
                    >
                      {t("learn-more.cta")}
                    </a>
                  </div>
                  <button
                    className="masterplan-button"
                    onClick={() => selectPlan("masterplan")}
                  >
                    {allPlans.masterplan.button}
                  </button>
                </PlanCard>
                <PlanCard styleName={"earthworks"} status={allPlans.earthworks}>
                  <div className="card-content">
                    <EarthworksIcon
                      className="plan-icon"
                      alt="EarthWorks product icon"
                    />
                    <h4>{t("earthworks")}</h4>
                    <h5>{t("earthworks.description")}</h5>
                    <a
                      href="https://asterra.io/products/earthworks/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="earthworks-link"
                    >
                      {t("learn-more.cta")}
                    </a>
                  </div>
                  <Link to="/support" className="earthworks-button">
                    {t("earthworks.select-earthworks.cta")}
                  </Link>
                </PlanCard>
              </div>
            </Step1>
            <Step2>
              <Recover status={allPlans.recover.section} />
              <Masterplan
                status={allPlans.masterplan.section}
                switchToRecover={() => selectPlan("recover")}
              />
            </Step2>
          </Container>
        </Wrapper>
      </Layout>
    </UserRoute>
  );
};
export default Contact;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "request", "buttons", "navigation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
