import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";
import { SubmitButton } from "../buttons/buttons";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/ducks/general";
import { useEffect } from "react";

const Container = styled.section`
  .title-row {
    padding: 0 0 1.5rem 0;
  }
`;

const Step2 = styled.section`
  .request-form {
    background: ${(props) => props.theme.colors.uiPalette.dark.darkGray};
    border-radius: 3px;
    padding: 3.75rem;
    margin: 1rem 0 0 0;
    h3 {
      font-size: 1.875rem;
      font-weight: 700;
      padding: 0 0 1.5rem 0;
    }
    textarea {
      transition: all 250ms;
      border: none;
      background: none;
      width: 100%;
      border-bottom: 1px solid ${(props) => props.theme.variables.form.border};
      padding: 0 0 0.75rem 0;
      margin: 0 0 0.5rem 0;
      color: ${(props) => props.theme.colors.primaryColors.white};
      height: 75px;
      &::placeholder {
        color: ${(props) => props.theme.variables.color};
        text-transform: uppercase;
      }
      &:focus {
        color: ${(props) => props.theme.colors.secondaryColors.green};
        outline: none !important;
        border-bottom: 1px solid
          ${(props) => props.theme.colors.secondaryColors.green};
      }
    }
    .message-row {
      margin: 1rem 0;
    }
    .project-selector {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      transition: all 250ms;
      border: none;
      background: none;
      width: 100%;
      color: ${(props) => props.theme.variables.color};
      text-transform: uppercase;
      padding: 0 0 0.75rem 0;
      border-bottom: 1px solid ${(props) => props.theme.variables.form.border};
      &:focus {
        color: ${(props) => props.theme.colors.secondaryColors.green};
        outline: none !important;
        border-bottom: 1px solid
          ${(props) => props.theme.colors.secondaryColors.green};
      }
    }
  }
  .contact-information-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    align-items: center;
  }
  .reminder-column {
    padding: 4rem;
    .reminder-title {
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.5;
    }
    .reminder-description {
      font-size: 0.9375rem;
      margin: 0.5rem 0;
    }
    .switch-button {
      border: none;
      background: none;
      color: ${(props) => props.theme.colors.secondaryColors.orange};
      font-weight: 700;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    .contact-information-container {
      grid-template-columns: 1fr;
    }
  }
  
`;

const Masterplan = (props) => {
  // Translation component
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const loginValues = useSelector((state) => state.general.loginValues);

  // Replace with your preferred function to submit New Project request
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    await axios.post(`/send-email`, {
      data: {
        userId: loginValues.userId,
        productType: data.requested_project_type,
        userMessage: data.message,
        subject: "New Project Request",
      },
    });

    // Replace with response from server when successfuly submitted
    navigate("/request-new-project/success");

    if (errors) {
      console.log(errors);
    }
  };
  return (
    <Container className={props.status} id="masterplan-tier">
      <Step2>
        <div className="title-row">
          <h2>{t("step-2.masterplan")}</h2>
          <p>{t("step-2.masterplan.description")}</p>
        </div>
        <div className="contact-information-container">
          <form className="request-form" onSubmit={handleSubmit(onSubmit)}>
            <h3>{t("request-new-project.h3")}</h3>
            <select
              {...register("requested_project_type", { required: true })}
              className="project-selector"
            >
              <option value="MasterPlan">
                {t("request-new-project.dropdown.masterplan")}
              </option>
              <option value="EarthWorks">
                {t("request-new-project.dropdown.earthworks")}
              </option>
            </select>
            <div className="message-row">
              <textarea
                {...register("message", { required: true })}
                placeholder={t("request-new-project.message.placeholder")}
              ></textarea>
              {errors.message && (
                <span className="error-message">
                  {t("request-new-project.message.error")}
                </span>
              )}
            </div>
            <SubmitButton value={t("request-new-project.cta")} />
          </form>
          <div className="reminder-column">
            <p className="reminder-title">{t("additional-info")}</p>
            <p className="reminder-description">
              {t("additional-info.description")}
            </p>
            <button className="switch-button" onClick={props.switchToRecover}>
              {t("additional-info.cta")}
            </button>
          </div>
        </div>
      </Step2>
    </Container>
  );
};
export default Masterplan;
