import React from "react";
import styled from "styled-components";

const Card = styled.article`
    display:flex;
    flex-flow: column;
    justify-content: space-between;
    border-radius:2px;
    padding: 1.875rem;
    transition: all 250ms;
    border: 1px solid ${(props) => props.theme.variables.cards.product.border};      
    a{        
        text-decoration: underline;        
    }
    h4{
        font-size: 1.875rem;
        font-weight:600;
    }
    h5{
        font-size:1.25rem;
        margin:0.5rem 0 0.75rem 0;        
    }
    h6{
        font-size:0.9375rem;
        font-weight:700;  
        margin:2rem 0 0 0;          
    }
    h6.earthworks{
        margin:2rem 0;
    }
    ul{
        font-size:0.9375rem;    
        padding:1rem 0;
        li{
            margin:0 0 0.7rem 0;      
            display:flex;
            align-items:start;            
        }
  }
  .plan-icon{
    margin:0 0 1rem 0;    
    max-width: 50px;
    path, ellipse, circle{
        transition: all 250ms;
    }
  }
  .checkmark-icon{
    min-width:10px;
    margin:6px 8px 0 0;    
  }
  .recover-link{
        &:hover{
            color:${props => props.theme.colors.primaryColors.blue};
        }
  }
  .earthworks-link{
        &:hover{
            color:${props => props.theme.colors.secondaryColors.green};
        }
  }
  @media (max-width:${props => props.theme.breakPoints.lg}){
      padding:1.875rem 1.25rem;
    }
  @media (max-width:${props => props.theme.breakPoints.md}){
      padding:1.875rem;
    }
`

export const PlanCard = ({status, styleName, children}) => {
    return (
        <Card className={status} id={styleName}>
            {children}            
        </Card>
    )
};

