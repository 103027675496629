import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { useForm } from "react-hook-form";
import { SecondarySubmitButton } from "../buttons/buttons";
import InformationIcon from "../../images/icons/information.svg";
import RecoverWhite from "../../images/icons/recover-white.svg";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/ducks/general";
import { useEffect } from "react";
import Checkbox from "../buttons/card-checkbox";
import { navigate } from "gatsby";

// const Checkbox = styled.label`
//   display: block;
//   height: 20px;
//   width: 20px;
//   position: relative;
//   cursor: pointer;
//   transform: scale(0.75);
//   /* Reset browser default */
//   input {
//     position: absolute;
//     opacity: 0;
//     cursor: pointer;
//     height: 0;
//     width: 0;
//   }
//   .checkmark {
//     border: 1px solid
//       ${(props) => props.theme.variables.buttons.checkbox.border};
//     position: absolute;
//     inset: 0;
//     height: 20px;
//     width: 20px;
//     transition: all 250ms;
//   }
//   &:hover input ~ .checkmark {
//     border: 1px solid
//       ${(props) => props.theme.variables.buttons.checkbox.hoverBorder};
//   }
//   input:checked ~ .checkmark {
//     background: ${(props) => props.theme.variables.buttons.checkbox.checked};
//     border: 1px solid
//       ${(props) => props.theme.variables.buttons.checkbox.checked};
//   }
//   .checkmark:after {
//     content: "";
//     position: absolute;
//     display: none;
//   }
//   input:checked ~ .checkmark:after {
//     display: block;
//   }
//   .checkmark:after {
//     left: 7px;
//     top: 3px;
//     width: 5px;
//     height: 10px;
//     border: solid #fff;
//     border-width: 0 3px 3px 0;
//     transform: rotate(45deg);
//   }
// `;

const style = {
  arrRow: {
    width: "50%",
    display: "flex",
    alignItems: "center",
  },
};

const Form = styled.form`
  .title-row {
    padding: 0 0 1.5rem 0;
  }
  .sub-title-row {
    padding: 0 0 1rem 0;
  }
  .active {
    background: ${(props) => props.theme.colors.primaryColors.blue};
    border: 1px solid ${(props) => props.theme.colors.primaryColors.blue};
    .tier-status {
      color: ${(props) => props.theme.colors.primaryColors.white} !important;
    }
  }
`;

const Step2 = styled.section`
  padding: 2rem 0;
  .plan-tier-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
  }

  .recover-icon {
    width: 50px;
    height: 50px;
    margin-right: 5px;
    margin-left: 5px;
  }

  .recover-header {
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.colors.primaryColors.blue};
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 2.5 !important;
  }

  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    .plan-tier-row {
      grid-template-columns: 1fr;
    }
  }
`;

const Tier = styled.label`
  display: flex;
  flex-flow: column;
  justify-content: start;
  background: transparent !important;
  border: 1px solid ${(props) => props.theme.colors.primaryColors.blue};
  border-radius: 3px;
  padding: 1.5rem;
  transition: all 250ms;
  input[type="radio"] {
    display: none;
  }

  .tier-title {
    cursor: pointer;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.25 !important;
    margin: 0.25rem 0 1.5rem 0;
  }

  .active {
    cursor: pointer;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.25 !important;
    margin: 0.25rem 0 1.5rem 0;
    background-color: black;
    border: none;
    color: ${(props) => props.theme.colors.primaryColors.blue};
  }

  .card-icon {
    margin-left: 0.3rem;
    margin-top: 0.13rem;
  }

  .tier-description {
    vertical-align: top;
    font-size: 1.1rem;
    padding: 1rem 0 0 0;
    display: flex;
    align-items: center;
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.primaryColors.blue};
    .tier-title {
      color: ${(props) => props.theme.colors.primaryColors.blue};
    }
  }
`;
const Step3 = styled.section`
    padding:2rem 0;
    .additional-options-container{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 2rem;
    }

    .recover-icon{
        width: 50px;
        height: 50px;
        margin-right: 5px;
        margin-left: 5px;
    }

    .addons-header{
        display: flex;
        align-items: center;
        background: ${(props) => props.theme.colors.primaryColors.blue};
        font-size:1.875rem;
        font-weight:700;
        line-height: 2.5 !important;
    }

    .card-container{
      display: grid;
      grid-template-columns: repeat(2,1fr);
      grid-gap: 2rem;
      border:1px solid ${(props) => props.theme.colors.primaryColors.blue}; 
      border-radius:3px;
      padding: 36px 0px 0px 24px;
  }

    .inner-li {
      display: flex;
      align-items: center;
      padding-bottom: 0.5rem;
    }

    .li-check-box {
      display: block;
      height: 20px;
      width: 20px;
      position: relative;
      cursor: pointer;
      transform: scale(0.75);
    }

    .check-box {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0px;
      width: 0px;
    }

    .check-mark {
      border: 1px solid rgb(153, 153, 153);
      position: absolute;
      inset: 0px;
      height: 20px;
      width: 20px;
      transition: all 250ms ease 0s;
    }

    .li-check-box input[type="checkbox"]:hover ~ .check-mark {
      border: 1px solid white;
    }
    
    .li-check-box input[type="checkbox"]:checked ~ .check-mark {
      background: rgb(0, 43, 255);
      border: 1px solid rgb(0, 43, 255);
    }
    
    .li-check-box input[type="checkbox"]:checked ~ .check-mark::after {
      content: "";
      position: absolute;
      left: 7px;
      top: 3px;
      width: 5px;
      height: 10px;
      border-style: solid;
      border-width: 0px 3px 3px 0px;
      transform: rotate(45deg);
    }

    .services-text {
      margin-left: 10px;
      font-size: 0.9rem;
    }

    .card-icon{
        margin-left: 0.3rem;
        margin-top: 0.13rem;
    }

    .card-style{
        display:flex;
        flex-flow: row;
        justify-content: space-between;
        background:transparent !important;
        padding:1.5rem;
        transition:all 250ms; 
        font-size:0.9rem; 
    }

    .add-on-item{
        display: flex;
        align-items: center;
        margin: 0.5rem 0;
        align-self:flex-start;
        span {
            padding: 0 0 0 0.75rem;
        }
    }
    .preference-column{    
    }
    .preference-radio-button{
        display: block;
        position: relative;              
        padding:0 0 0 1.75rem;
        margin: 0.5rem 0;
        cursor: pointer;        
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        /* Hide browser default */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
        .checkmark {
            position: absolute;
            inset:3px auto auto 0;            
            height: 15px;
            width: 15px;            
            padding-right: 10px
            border-radius: 50%;
            border: 1px solid ${(props) =>
              props.theme.variables.buttons.checkbox.border};            
                &:after{
                    content: "";
                    position: absolute;                                                  
                    inset: 2px auto auto 2px;
                    transition: scale 500ms, opacity 500ms;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    transform: scale(0);
                    opacity:0;
                    background: ${(props) =>
                      props.theme.colors.primaryColors.blue};
                }
        }
        input:checked ~ .checkmark {            
            &:after{          
                opacity:1;      
                transform: scale(1);
            }
        }        
    }
    .submit-button-row{
        padding:0.5rem 0 0 0;
        display: flex;
        align-items: center;
        gap:2rem;
        .contact-information{
            font-size:0.75rem;
            a{
                text-decoration: underline;
            }
        }
    }
    @media (max-width: ${(props) => props.theme.breakPoints.sm}){
        .additional-options-container{
            grid-template-columns: 1fr;
        }
        .submit-button-row{
            flex-flow: column;
        }
    }    
`;

const Recover = (props) => {
  // Translation component
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const loginValues = useSelector((state) => state.general.loginValues);

  // Recover description options
  // each item references to [description, tooltip data]
  const recoverOptions = {
    detect: [
      ["2 licences: EO discover", ""],
      [
        "Base line Leak Analysis",
        "A system-wide analysis by ASTERRA Recover of the locations identified <br> as having a high probability of subsurface leakage.",
      ],
      [
        "Leak Locations",
        "A list of potential leaks (Points of Interest/POIs) for investigation <br> based on SAR algorithm results, machine learning, <br> and acoustic theory analysis.",
      ],
      // [
      //   "TBD Dashboard (2 Licenses)",
      //   "Access for 2 users to ASTERRA’s TBD dashboard to track leak investigation <br> results during the subscription period.",
      // ],
      [
        "U-Collect & U-View Apps (2 Licenses)",
        "Access to field investigation input and viewing software.",
      ],
      ["Online Support", ""],
      [
        "Customized Success Plan",
        "An execution and success plan that ASTERRA customizes to your requirements, <br> then reviews and updates during the subscription period.",
      ],
      [
        "Best-Practice Tutorials",
        "Online tutorials for using and troubleshooting the TBD dashboard, U-Collect, and U-View applications.",
      ],
    ],
    prevent: [
      ["4 licences: EO discover", ""],
      [
        "Base line Leak Analysis",
        "A system-wide analysis by ASTERRA Recover of the locations identified <br> as having a high probability of subsurface leakage.",
      ],
      [
        "Leak Locations",
        "A list of potential leaks (Points of Interest/POIs) for investigation <br> based on SAR algorithm results, machine learning, <br> and acoustic theory analysis.",
      ],
      // [
      //   "TBD Dashboard (4 Licenses)",
      //   "Access for 4 users to ASTERRA’s TBD dashboard to track leak investigation <br> results during the subscription period.",
      // ],
      [
        "U-Collect & U-View Apps (4 Licenses)",
        "Access to field investigation input and viewing software.",
      ],
      [
        "Temporal and Spatial Leak Analysis",
        "Advanced system-wide temporal and spatial leak analysis based on ASTERRA <br> Recover and MasterPlan, with multiple satellite passes across time and space <br> to identify locations with a high probability of subsurface leaks.",
      ],
      [
        "Prioritized Leak locations for field investigation",
        "A prioritized list of potential leaks. Prioritization is enabled by spatial <br> and temporal analysis interpreted by an ASTERRA technician, the <br> customer field investigation process, and system age, pipe type, <br> pressure, etc.",
      ],
      ["Online Support", ""],
      [
        "Customized Success Plan",
        "An execution and success plan that ASTERRA customizes to your requirements, <br> then reviews and updates during the subscription period.",
      ],
      [
        "Best-Practice Tutorials",
        "Online tutorials for using and troubleshooting the TBD dashboard, U-Collect, and U-View applications.",
      ],
    ],
    advise: [
      ["6 licences: EO discover", ""],
      [
        "Base line Leak Analysis",
        "A system-wide analysis by ASTERRA Recover of the locations identified <br> as having a high probability of subsurface leakage.",
      ],
      [
        "Leak Locations",
        "A list of potential leaks (Points of Interest/POIs) for investigation <br> based on SAR algorithm results, machine learning, <br> and acoustic theory analysis.",
      ],
      // [
      //   "TBD Dashboard (6 Licenses)",
      //   "Access for 6 users to ASTERRA’s TBD dashboard to track leak investigation <br> results during the subscription period.",
      // ],
      [
        "U-Collect & U-View Apps (6 Licenses)",
        "Access to field investigation input and viewing software.",
      ],
      [
        "Enhanced Temporal and Spatial Leak Analysis",
        "Advanced system-wide temporal and spatial leak analysis based on ASTERRA <br> Recover and MasterPlan, with multiple satellite passes across time and space <br> to identify locations with a high probability of subsurface leaks.",
      ],
      [
        "Prioritized Leak locations for field investigationl",
        "A prioritized list of potential leaks. Prioritization is enabled by spatial <br> and temporal analysis interpreted by an ASTERRA technician, the <br> customer field investigation process, and system age, pipe type, <br> pressure, etc.",
      ],
      [
        "MasterPlan Pipe Deficiency Map",
        "Refine pipe replacement planning models or water system master plans with <br> MasterPlan’s pipe deficiency analysis/map. Observed deficiency scored <br> 1 (low) to 5 (high). Compatible with all GIS-based asset planning model <br> tools. Integrates pipe age, material, repair history, and other data.",
      ],
      [
        "Esri ArcGIS Field Maps Compatibility",
        "Integration support for Esri ArcGIS Field Maps and the ASTERRA Recover product, <br> including technical implementation assistance and identification of potential <br> use cases.",
      ],
      [
        "Personal Success Manager",
        "An ASTERRA success manager is assigned to your account to provide reviews, <br> updates, troubleshooting, and other services via monthly virtual meetings.",
      ],
      ["Online Support", ""],
      [
        "Customized Success Plan",
        "An execution and success plan that ASTERRA customizes to your requirements, <br> then reviews and updates during the subscription period.",
      ],
      [
        "Best-Practice Tutorials",
        "Online tutorials for using and troubleshooting the TBD dashboard, U-Collect, and U-View applications.",
      ],
    ],
  };

  // Additional services options
  // each row has its array and each item in a row refrences to [description, tooltip data]
  const additionalServices = [
    "Additional Licenses",
    "Final Program Report",
    "Customer Success Manager (CSM) Project Management Services",
    "Executive Business Review",
    "3-Day On-site Field Support",
    "Subcontracted Leak Detection Coordination",
    "MasterPlan Pipe Deficiency Map",
    "Subcontracted Leak Detection Services",
    "Esri ArcGIS Field Maps Compatibility",
    "Acoustic Leak Detection Training",
  ]

  const additionalServicesTooltip = [
        "ASTERRA will offer additional licenses for the ASTERRA Dashboard, U-Collect, <br> and U-View access, as requested by the customer.",
        "ASTERRA will provide a cost-benefit report summarizing program progress, <br> estimated water saved, and impact of the project on utility <br> non-revenue water savings.  Report is provided in <br> PDF format.",
        "ASTERRA will offer the services of a dedicated customer success Manager <br> who will provide monthly online virtual support meetings to update <br> and review project status, provide general dashboard updates <br> and review, monitor performance, and provide troubleshooting <br> and support.",
        "ASTERRA will lead an interactive customer-focused meeting detailing the return <br> on investment of the ASTERRA program, summarizing the goals, objectives, <br> and performance of the program. A dedicated Customer Support Manager <br> will present materials in-person to utility executive leadership <br> and utility personnel.",
        "ASTERRA provides in-person field support by the Customer Success Manager in <br> support of field resources best practices and U-Collect <br> application.  Services to be scheduled a minimum of <br> four weeks in advance.",
        "ASTERRA Customer Services Manager will provide utility leak detection <br> coordination support, including prioritization and scheduling <br> assistance for up to four (4) hours per subscription period.",
        "ASTERRA’s MasterPlan analysis uses a proprietary algorithm to score utility <br> pipe segments exhibiting non-surfacing leaks and analyzes <br> leak clusters over time. The pipes are scored on a 1 – 5 <br> scale, from a low level of deficiency observed to high <br> levels of deficiency. Planning priorities and responses <br> can be established accordingly. MasterPlan pipe deficiency <br> is available for Detect and Prevent subscription <br> levels only.",
        "ASTERRA will provide the services of a preferred leak detection vendor, <br> subcontracted for five (5) days per 40-hour leak detection <br> period. Following ASTERRA’s best practices, identified leak <br> locations will be investigated to pinpoint water loss. The <br> schedule will be coordinated with the vendor and utility <br> by ASTERRA Customer Success Manager.",
        "ASTERRA will provide support with the integration of Esri ArcGIS Field Maps <br> and ASTERRA Recover product, including technical implementation <br> assistance and identification of potential use cases. Esri ArcGIS <br> Field Maps Compatibility Services are available for Detect and <br> Prevent subscription levels only.",
        "ASTERRA will assist in developing the customer's internal leak detection <br> resources through in-person training. One day of focused classroom <br> training will be provided on acoustic leak detection equipment, <br> theory, and best practices. The second day covers the <br> ASTERRA U-Collect application, including field training <br> and hands-on demonstration. Includes review of <br> current equipment and best practice <br> recommendations.",
  ];

  // Replace with your preferred function to submit New Project request
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    // seperating the recover_tier from the addons
    const recoverTier = data.recover_tier;
    delete data.recover_tier;
    axios
      .post(`/send-email`, {
        data: {
          userId: loginValues.userId,
          productType: `Recover - ${recoverTier}`,
          subject: "New Project Request",
          addons: data,
          role: loginValues.role,
        },
      })
      .then((res) => {
        // Replace with response from server when successfuly submitted
        navigate("/request-new-project/success");
      });

    if (errors) {
      console.log(errors);
    }
  };

  // Set selected tier plan
  const [allTiers, setAllTiers] = useState({
    detect: {
      status: "",
      button: "",
    },
    prevent: {
      status: "",
      button: "",
    },
    advise: {
      status: "",
      button: "",
    },
  });

  const tierSelected = (tier) => {
    if (tier === "detect") {
      setAllTiers({
        detect: {
          status: "active",
          button: "",
        },
        prevent: {
          status: "",
          button: "",
        },
        advise: {
          status: "",
          button: "",
        },
      });
    } else if (tier === "prevent") {
      setAllTiers({
        detect: {
          status: "",
          button: "",
        },
        prevent: {
          status: "active",
          button: "",
        },
        advise: {
          status: "",
          button: "",
        },
      });
    } else {
      setAllTiers({
        detect: {
          status: "",
          button: "",
        },
        prevent: {
          status: "",
          button: "",
        },
        advise: {
          status: "active",
          button: "",
        },
      });
    }
  };
  return (
    <Form
      id="recover-tier"
      className={props.status}
      onSubmit={handleSubmit(onSubmit)}
    >
      <ReactTooltip />
      <Step2>
        <div className="title-row">
          <h2>{t("step-2.recover")}</h2>
          <p>{t("step-2.recover.description")}</p>
        </div>
        <div className="recover-header">
          <RecoverWhite className="recover-icon" />
          <span>{t("recover-title")}</span>
        </div>
        <div className="plan-tier-row">
          <Tier
            htmlFor={"detect"}
            onClick={() => tierSelected("detect")}
            className={allTiers.detect.status}
          >
            <p
              className={
                allTiers.detect.status === "" ? "tier-title" : "active"
              }
            >
              {t("detect")}
            </p>
            <ul>
              {recoverOptions.detect.map((data_arr, index) => {
                return (
                  <li className="tier-description" key={index}>
                    <span>
                      {data_arr[0]}
                      {data_arr[1] && (
                        <InformationIcon
                          data-multiline={true}
                          data-tip={data_arr[1]}
                          className="card-icon"
                        />
                      )}
                    </span>
                  </li>
                );
              })}
            </ul>
            <input
              type="radio"
              value="Detect"
              {...register("recover_tier", { required: true })}
              id="detect"
            ></input>
          </Tier>
          <Tier
            htmlFor={"prevent"}
            onClick={() => tierSelected("prevent")}
            className={allTiers.prevent.status}
          >
            <p
              className={
                allTiers.prevent.status === "" ? "tier-title" : "active"
              }
            >
              {t("prevent")}
            </p>
            <ul>
              {recoverOptions.prevent.map((data_arr, index) => {
                return (
                  <li className="tier-description" key={index}>
                    <span>
                      {data_arr[0]}
                      {data_arr[1] && (
                        <InformationIcon
                          data-multiline={true}
                          data-tip={data_arr[1]}
                          className="card-icon"
                        />
                      )}
                    </span>
                  </li>
                );
              })}
            </ul>
            <input
              type="radio"
              value="Prevent"
              {...register("recover_tier", { required: true })}
              id="prevent"
            ></input>
          </Tier>
          <Tier
            htmlFor={"advise"}
            onClick={() => tierSelected("advise")}
            className={allTiers.advise.status}
          >
            <p
              className={
                allTiers.advise.status === "" ? "tier-title" : "active"
              }
            >
              {t("advise")}
            </p>
            <ul>
              {recoverOptions.advise.map((data_arr, index) => {
                return (
                  <li className="tier-description" key={index}>
                    <span>
                      {`${data_arr[0]}  `}
                      {data_arr[1] && (
                        <InformationIcon
                          data-multiline={true}
                          data-tip={data_arr[1]}
                          className="card-icon"
                        />
                      )}
                    </span>
                  </li>
                );
              })}
            </ul>
            <input
              type="radio"
              value="Advise"
              {...register("recover_tier", { required: true })}
              id="advise"
            ></input>
          </Tier>
        </div>
      </Step2>
      <Step3>
        <h2>{t("step-3.recover")}</h2>
        <div className="additional-options-container">
          <div className="add-on-column">
            <div className="sub-title-row">
              <p>{t("step-3.recover.description")}</p>
            </div>
          </div>
        </div>
        <div className="addons-header">
          <RecoverWhite className="recover-icon" />
          <span>{t("addons-title")}</span>
        </div>
        <ul className="card-container">
          {additionalServices.map((service, index) => {
            const tooltipKey = Object.keys(additionalServices)[index];
            const tipValue = additionalServicesTooltip[tooltipKey];
            return (
              <li key={index}>
                <div className="inner-li">
                  <label className="li-check-box">
                    <input
                      className="check-box"
                      name={service}
                      type="checkbox"
                      {...register(service)}
                    />
                    <span className="check-mark"></span>
                  </label>
                  <span className="services-text">{service}{" "}</span>
                  <InformationIcon
                    data-tip={tipValue}
                    className="card-icon"
                  />
                </div>
              </li>
            );
          })}
          <li />
          <li
            style={{
              display: "flex",
              justifyContent: "end",
              paddingBottom: "1rem",
              paddingRight: "1rem",
            }}
          >
            <div className="submit-button-row">
              <SecondarySubmitButton value={"Submit"} />
              <p className="contact-information">
                <Trans
                  i18nKey="billing-preference.description"
                  components={{
                    cta: <a href="mailto:contactsupport@asterra.io">Contact us</a>,
                  }}
                />
              </p>
            </div>
          </li>
        </ul>
      </Step3>
    </Form>
  );
};

export default Recover;
